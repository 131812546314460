import { Components, Theme } from '@mui/material';

export const kyronComponents: Components<Omit<Theme, 'components'>> = {
  MuiAlert: {
    styleOverrides: {
      icon: {
        position: 'relative',
        top: '2px',
      },
      message: {
        flex: 1,
        overflow: 'hidden',
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      listbox: ({ theme }) => ({
        backgroundColor: theme.palette.surface.main,
      }),
    },
  },
  MuiButton: {
    defaultProps: {
      variant: 'contained',
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        borderRadius: '100px',
        textTransform: 'none',
        padding: '10px 24px',
        // --  Prevents the button from wrapping the label text
        whiteSpace: 'nowrap',
        minWidth: 'max-content',
        // ---------------------------------------------------
      },
      text: {
        padding: '10px 12px',
      },
    },
    variants: [
      {
        props: {
          variant: 'raised',
        },
        style: ({ theme }) => ({
          backgroundColor: theme.palette.surfaceContainer.main,
          color: theme.palette.primary.main,
          boxShadow: theme.shadows[1],
          '&:hover, &:focus': {
            backgroundColor: theme.palette.primaryContainer.main,
            boxShadow: theme.shadows[1],
          },
        }),
      },
      {
        props: {
          variant: 'raised',
          color: 'secondary',
        },
        style: ({ theme }) => ({
          color: theme.palette.secondary.main,
          '&:hover, &:focus': {
            backgroundColor: theme.palette.secondaryContainer.main,
          },
        }),
      },
      {
        props: {
          variant: 'raised',
          color: 'success',
        },
        style: ({ theme }) => ({
          color: theme.palette.success.main,
          '&:hover, &:focus': {
            backgroundColor: theme.palette.successContainer.main,
          },
        }),
      },
      {
        props: {
          variant: 'raised',
          color: 'error',
        },
        style: ({ theme }) => ({
          color: theme.palette.error.main,
          '&:hover, &:focus': {
            backgroundColor: theme.palette.errorContainer.main,
          },
        }),
      },
      {
        props: {
          variant: 'raised',
          disabled: true,
        },
        style: ({ theme }) => ({
          backgroundColor: theme.palette.action.disabledBackground,
          color: theme.palette.action.disabled,
        }),
      },
    ],
  },
  MuiButtonGroup: {
    defaultProps: {
      variant: 'contained',
      disableElevation: true,
    },
  },
  MuiBreadcrumbs: {
    styleOverrides: {
      separator: {
        marginLeft: '4px',
        marginRight: '4px',
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: theme => ({
      body: { ...theme.typography.bodyLarge },
    }),
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: '28px',
      },
      paperWidthSm: {
        maxWidth: '480px',
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: '24px',
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: '0 24px',
      },
    },
  },
  MuiDialogTitle: {
    defaultProps: {
      variant: 'headlineSmall',
    },
    styleOverrides: {
      root: {
        padding: '24px 24px 16px 24px',
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        padding: '12px',
        width: '320px',
      },
    },
  },
  MuiIconButton: {
    variants: [
      {
        props: {
          variant: 'filled',
        },
        style: ({ theme }) => ({
          background: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.dark,
          },
        }),
      },
    ],
  },
  MuiLink: {
    defaultProps: {
      underline: 'hover',
    },
  },
  MuiListItem: {
    defaultProps: {
      disablePadding: true,
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: 'inherit',
        marginRight: '8px',
        minWidth: '0px',
      },
    },
  },
  MuiListItemText: {
    defaultProps: {
      secondaryTypographyProps: {
        variant: 'bodySmall',
      },
    },
    styleOverrides: {
      secondary: {
        color: 'inherit',
      },
    },
  },
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
    styleOverrides: {
      root: {
        backgroundImage: 'none',
      },
      rounded: {
        borderRadius: '24px',
      },
    },
  },
  MuiPopover: {
    defaultProps: {
      elevation: 2,
    },
    styleOverrides: {
      paper: {
        border: `1px solid white`,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderBottomColor: theme.palette.divider,
        padding: '8px 16px',
        height: '56px',
      }),
      head: ({ theme }) => ({
        padding: '12px 16px',
        height: 'auto',
        fontFamily: theme.typography.labelMedium.fontFamily,
        fontSize: theme.typography.labelMedium.fontSize,
        fontWeight: theme.typography.labelMedium.fontWeight,
        lineHeight: theme.typography.labelMedium.lineHeight,
      }),
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.surface.main,
      }),
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.divider,
      }),
      indicator: {
        borderTopRightRadius: '6px',
        borderTopLeftRadius: '6px',
        height: '3px',
      },
    },
  },
  MuiToggleButton: {
    variants: [
      {
        props: {
          variant: 'iconButton',
        },
        style: ({ theme }) => ({
          borderRadius: '100px !important',
          border: `1px solid ${theme.palette.primary.main} !important`,
          color: theme.palette.primary.main,
          '&:hover, &:focus': {
            backgroundColor: theme.palette.surface.dark,
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:hover, &:focus': {
              backgroundColor: theme.palette.primary.dark,
            },
          },
        }),
      },
    ],
  },
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        displayLarge: 'h1',
        displayMedium: 'h1',
        displaySmall: 'h1',
        headlineLarge: 'h2',
        headlineMedium: 'h3',
        headlineSmall: 'h4',
        titleLarge: 'h2',
        titleMedium: 'h3',
        titleSmall: 'h4',
        bodyLarge: 'p',
        bodyMedium: 'p',
        bodySmall: 'p',
      },
    },
  },
};
