import React from 'react';
import { CatalogLayout, loader as catalogLayoutLoader } from 'components/CatalogLayout/CatalogLayout';
import { User } from 'controllers/types';
import { Navigate, RouteObject } from 'react-router-dom';
import { Player } from 'components/Player/Player';
import { KyronLayout } from './KyronLayout/KyronLayout';
import { LessonEditor } from './LessonEditor/LessonEditor';
import { LessonEdit, loader as lessonEditLoader } from './LessonEditor/LessonEdit';
import { MisconceptionEditor, loader as misconceptionLoader } from './MisconceptionEditor/MisconceptionEditor';
import { CourseEditor } from './CourseEditor/CourseEditor';
import { UnitEditor } from './UnitEditor/UnitEditor';
import { LessonInstanceReport } from './Reports/LessonInstanceReport';
import { TrainingLogs } from './Trainings/TrainingLogs';
import { ProtectedRoute } from './utils/ProtectedRoute';
import { PdfViewer } from './common/PdfViewer';
import { TestRunner } from './AutomatedTest/TestRunner';
import { AdminLessons } from './admin/AdminLessons';
import { Library, Members } from './Studio';
import { Playlists } from './Studio/Library/Playlists';
import { PlaylistEdit } from './Studio/Library/PlaylistEdit';
import { PlaylistNew } from './Studio/Library/PlaylistNew';
import { LessonBuilder } from './admin/LessonBuilder/LessonBuilder';
import { AdminOrganizations, loader as adminOrganizationsLoader } from './admin/AdminOrganizations/AdminOrganizations';
import { AdminUsers, loader as adminUsersLoader } from './admin/AdminUsers/AdminUsers';
import { ErrorPage } from './ErrorPage/ErrorPage';
import { Home } from './Home/Home';
import { CatalogUnit } from './CatalogUnit/CatalogUnit';
import { CatalogLesson } from './CatalogLesson/CatalogLesson';
import { TeacherDashboard } from './Teacher/TeacherDashboard';
import { AssignmentsPage } from './Teacher/AssignmentsPage';
import { AssignmentDetails } from './Teacher/AssignmentDetails';
import { LtiPlatformPage } from './LtiPlatform/LtiPlatformPage';
import { CompletePayment } from './Payments/CompletePayment';
import { PaymentSubscriptions } from './Payments/PaymentSubscriptions';
import { StudioLayout } from './StudioLayout/StudioLayout';
import {
  redirectNewUrlToVideoIfSingleLesson,
  redirectOldUrlToVideoIfSingleLesson,
} from '../controllers/react-query/lessonContainerHooks';
import { Settings } from './Studio/Settings/Settings';
import { Tutors } from './Studio/Tutors/Tutors';
import { TutorEdit } from './Studio/Tutors/TutorEdit';
import { TutorNew } from './Studio/Tutors/TutorNew';
import { App } from './App';
import { AdminLayout } from './admin/AdminLayout/AdminLayout';
import { MarketplaceLayout } from './Marketplace/MarketplaceLayout/MarketplaceLayout';
import { MarketplaceHome } from './Marketplace/MarketplaceHome/MarketplaceHome';
import { ChannelLayout } from './Channels/ChannelLayout/ChannelLayout';
import { ChannelHome } from './Channels/ChannelHome/ChannelHome';
import { LessonLayout, MinimalLessonLayout } from './Lesson/LessonLayout/LessonLayout';
import { LessonNavigator } from './Lesson/LessonNavigator/LessonNavigator';
import { LtiPlatformLessonsIframeView } from './LtiPlatform/LtiPlatformLessonsIframeView';
import { Pricing } from './Pricing/Pricing';
import { EditorRoutes } from './Studio/Editor/EditorRoutes';
import { OrganizationAnalytics } from './Studio/OrganizationAnalytics/OrganizationAnalytics';
import { StudentDashboard } from './Studio/StudentDashboard/StudentDashboard';
import { LibraryLayout } from './Studio/Library/LibraryLayout';
import { MarketingHome } from './MarketingHome/MarketingHome';

export function getRoutes(user: User | null): RouteObject[] {
  return [
    {
      path: '/',
      element: <App />,
      errorElement: <ErrorPage />,
      children: [
        {
          element: <MarketingHome />,
          path: 'marketing',
        },
        {
          element: <MarketplaceLayout />,
          children: [
            {
              index: true,
              element: <MarketplaceHome />,
            },
            {
              path: 'channels/:channelId',
              element: <ChannelLayout />,
              children: [
                {
                  index: true,
                  element: <ChannelHome />,
                },
                {
                  path: 'collections',
                  element: <ChannelHome />,
                },
              ],
            },
          ],
        },
        {
          path: '/k12',
          element: <KyronLayout user={user} />,
          loader: () => user,
          children: [
            {
              errorElement: <ErrorPage />,
              children: [
                {
                  element: <CatalogLayout />,
                  loader: catalogLayoutLoader,
                  children: [
                    {
                      errorElement: <ErrorPage />,
                      children: [
                        {
                          index: true,
                          element: <Home />,
                        },
                        {
                          path: 'demo',
                          element: <Home />,
                        },
                        {
                          path: 'math',
                          element: <Home />,
                        },
                        {
                          path: 'units',
                          children: [
                            {
                              path: ':unitId',
                              element: <CatalogUnit />,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          // BEWARE:
          // This path and paths under it is being used to render lessons in iframe
          // If any changes happen here, make sure to reflect those changes in
          // app/services/embed_service.rb
          path: 'units/:unitId/lessons/:lessonId', // TODO(ege): This is actually lesson_container_id, name it so
          element: <CatalogLesson />,
          loader: async ({ params }) => {
            if (params && params.lessonId) {
              return await redirectOldUrlToVideoIfSingleLesson(params.lessonId);
            }
            return null;
          },
        },
        {
          // BEWARE:
          // This path and paths under it is being used to render lessons in iframe
          // If any changes happen here, make sure to reflect those changes in
          // app/services/embed_service.rb
          path: 'lesson_collections/:lessonCollectionId',
          element: <CatalogLesson />,
          loader: async ({ params }) => {
            if (params && params.lessonCollectionId) {
              return await redirectNewUrlToVideoIfSingleLesson(params.lessonCollectionId);
            }
            return null;
          },
        },
        {
          // BEWARE:
          // This path and paths under it is being used to render lessons in iframe
          // If any changes happen here, make sure to reflect those changes in
          // app/services/embed_service.rb
          path: '/lti_platforms/:platformId/lessons',
          element: <LtiPlatformLessonsIframeView />,
        },
        {
          // BEWARE:
          // This path is being used to render lessons in iframe
          // If any changes happen here, make sure to reflect those changes in
          // app/services/embed_service.rb
          path: '/video_player/:lessonId',
          errorElement: <ErrorPage />,
          element: <MinimalLessonLayout />,
          children: [
            {
              index: true,
              element: <Player />,
            },
            {
              path: 'segments/:segmentId',
              element: <Player />,
            },
          ],
        },
        {
          path: 'lessons/:lessonCollectionId',
          element: <LessonLayout />,
          children: [
            {
              index: true,
              element: <LessonNavigator />,
            },
            {
              path: ':lessonId',
              element: <Player />,
            },
          ],
        },
        {
          path: 'studio',
          element: <ProtectedRoute user={user} />,
          children: [
            {
              element: <StudioLayout />,
              children: [
                {
                  index: true,
                  element: <Navigate to='/studio/library' replace />,
                },
                {
                  path: 'library',
                  children: [
                    {
                      element: <LibraryLayout />,
                      children: [
                        {
                          index: true,
                          element: <Library />,
                        },
                        {
                          path: 'playlists',
                          element: <Playlists />,
                        },
                      ],
                    },
                    {
                      path: 'playlists/new',
                      element: <PlaylistNew />,
                    },
                    {
                      path: 'playlists/:playlistId',
                      element: <PlaylistEdit />,
                    },
                  ],
                },
                {
                  path: 'tutors',
                  element: <Tutors />,
                },
                {
                  path: 'tutors/new',
                  element: <TutorNew />,
                },
                {
                  path: 'tutors/:tutorId',
                  element: <TutorEdit />,
                },
                {
                  path: 'members',
                  element: <Members />,
                },
                {
                  path: 'settings',
                  element: <Settings />,
                },
                {
                  path: 'lti_platforms',
                  element: <LtiPlatformPage />,
                },
                {
                  path: 'classrooms',
                  children: [
                    {
                      index: true,
                      element: <TeacherDashboard />,
                    },
                    {
                      path: ':classroomId',
                      element: <AssignmentsPage />,
                    },
                    {
                      path: ':classroomId/assignments/:lessonCollectionId',
                      element: <AssignmentDetails />,
                    },
                  ],
                },
                {
                  path: 'internal_lesson_editor',
                  element: <LessonEditor />,
                },
                {
                  path: 'internal_lesson_editor/:lessonId/edit',
                  element: <LessonEdit />,
                  loader: lessonEditLoader,
                },
                {
                  path: 'misconception_editor',
                  element: <MisconceptionEditor />,
                  loader: misconceptionLoader,
                },
                {
                  path: 'course_editor',
                  element: <CourseEditor />,
                },
                {
                  path: 'unit_editor',
                  element: <UnitEditor />,
                },
                {
                  path: 'test_runner',
                  element: <TestRunner />,
                },
                {
                  path: 'organization_analytics',
                  element: <OrganizationAnalytics />,
                },
                {
                  path: 'student_dashboard',
                  element: <StudentDashboard />,
                },
              ],
            },
            {
              path: 'lesson-builder',
              element: <LessonBuilder />,
            },
            {
              path: ':lessonId/*',
              element: <EditorRoutes />,
            },
          ],
        },
        {
          path: 'admin',
          element: <ProtectedRoute user={user} />,
          children: [
            {
              element: <AdminLayout />,
              children: [
                {
                  index: true,
                  element: <Navigate to='/admin/users' replace />,
                },
                {
                  path: 'lessons',
                  element: <AdminLessons />,
                },
                {
                  path: 'reports',
                  element: <LessonInstanceReport />,
                },
                {
                  path: 'training',
                  element: <TrainingLogs />,
                },
                {
                  path: 'users',
                  element: <AdminUsers />,
                  loader: adminUsersLoader,
                },
                {
                  path: 'organizations',
                  element: <AdminOrganizations />,
                  loader: adminOrganizationsLoader,
                },
                {
                  path: 'payment_subscriptions',
                  element: <PaymentSubscriptions />,
                },
                {
                  path: 'complete_payment/:sessionId',
                  element: <CompletePayment />,
                },
              ],
            },
          ],
        },
        {
          path: 'pricing',
          element: <Pricing />,
        },
        {
          path: 'terms_of_service',
          element: <KyronLayout user={user} />,
          children: [
            {
              index: true,
              element: <PdfViewer fileName='terms_of_service.pdf' />,
            },
          ],
        },
        {
          path: 'privacy_policy',
          element: <KyronLayout user={user} />,
          children: [
            {
              index: true,
              element: <PdfViewer fileName='privacy_policy.pdf' />,
            },
          ],
        },
        {
          path: '/classrooms',
          element: <Navigate to='/studio/classrooms' replace />,
        },
      ],
    },
  ];
}
