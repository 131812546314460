import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { devConsole } from '../../utils/logUtils';
import { useLessonSectionsQuery } from '../../../controllers/react-query';
import { LessonSection } from '../../../controllers/types';

export type EditorState = {
  lessonId?: string;
  activeSegmentId?: string;
  activeSectionId?: string;
  segmentWarnings?: { segmentId: number; warning: string }[];
};

type EditorCtx = {
  editorState: EditorState;
  setEditorState: (newState?: Partial<EditorState> | ((prevState: EditorState) => Partial<EditorState>)) => void;
  goToSegment: (segmentId: string | number) => void;
  goToFirstSegmentOfActiveSection: () => void;
  goToFirstSegment: (updatedLessonSectionList?: LessonSection[]) => void;
};

export const EditorContext = createContext({} as EditorCtx);
export const useEditorContext = () => useContext(EditorContext);

/**
 * Initializes the editor context with the active segment and section ids
 */
export const useInitEditorContext = () => {
  const navigate = useNavigate();
  const { lessonId, segmentId } = useParams();
  const { data: sectionData } = useLessonSectionsQuery(lessonId);
  const lessonSections = sectionData?.lesson_sections || [];

  // EDITOR STATE
  const [editorState, $$setEditorState] = useState<EditorState>({
    lessonId,
    activeSegmentId: '',
    activeSectionId: '',
    segmentWarnings: [],
  });
  devConsole.debug('editorState', editorState);

  // This is a wrapper to make sure the editorState is always updated with the new state and never overridden entirely
  function setEditorState(newState?: Partial<EditorState> | ((prevState: EditorState) => Partial<EditorState>)) {
    // Handle the callback function case
    if (typeof newState === 'function') return $$setEditorState(p => ({ ...p, ...newState(p) }));
    // Handle the object case
    $$setEditorState(p => ({ ...p, ...newState }));
  }

  // This effect will set the segmentId and its sectionId in EditorState when the segmentId changes
  useEffect(updateActiveSectionAndSegmentOnChange, [sectionData, segmentId]);
  function updateActiveSectionAndSegmentOnChange() {
    const activeSection = (sectionData?.lesson_sections || []).find(sectionOfActiveSegment(segmentId));
    setEditorState({ activeSectionId: activeSection?.id.toString(), activeSegmentId: segmentId });
  }

  // EDITOR ACTIONS

  // Go to a specific segment
  const goToSegment = (targetSegmentId: string | number) => {
    // section will be updated automatically as a side effect of the segment change in useEffect above
    setEditorState({ activeSegmentId: targetSegmentId.toString() });
    navigate(`/studio/${lessonId}/segments/${targetSegmentId}`);
  };

  // Go to first segment of the first section
  const goToFirstSegment = (updatedLessonSectionList?: LessonSection[]) => {
    const sections = updatedLessonSectionList || lessonSections;
    const firstSegment = sections[0]?.segments[0]?.id.toString();
    goToSegment(firstSegment);
  };

  // Go to the first segment of the active section. If there is no active section, go to 1st section > 1st segment
  const goToFirstSegmentOfActiveSection = () => {
    if (editorState.activeSectionId) {
      const activeSection = lessonSections.find(s => s.id === Number(editorState.activeSectionId));
      const firstSegmentOfActiveSection = activeSection?.segments[0]?.id;

      if (!firstSegmentOfActiveSection) return goToFirstSegment();

      goToSegment(firstSegmentOfActiveSection);
    }
  };

  return {
    editorState,
    setEditorState,
    goToSegment,
    goToFirstSegmentOfActiveSection,
    goToFirstSegment,
  } satisfies EditorCtx;
};

// HELPERS
// Curried function to be used as lesson_sections.find(sectionOfActiveSegment(segmentId))
export function sectionOfActiveSegment(segmentId?: string) {
  return (section: LessonSection) => hasSegment(section, segmentId);
}
// Returns true if the given segmentId is part of the given section
export function hasSegment(section: LessonSection, segmentId?: string) {
  return section.segments.some(segment => segment.id.toString() === segmentId);
}
